@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

:root {
  --primary-green: #8CC63F;
  --primary-blue: #0071BC;
  --primary-gray-dark: #3B3A34;
  --background-light: #FAFBFC;
  --background-medium: #F5F6F9;
  --background-medium2: #889AA5;
  --background-dark: #889AA5;
  --system-error: #FF5656;
  --system-alert: #FF5A00;
  --system-info: #278EFF;
  --system-success: #2BE062;
  --default-white: #fff;
  --default-black: #000;
  --gradient01: linear-gradient(270deg, var(--primary-blue) 0%, var(--primary-green) 100%);
  --gradient02: linear-gradient(89.54deg, var(--primary-blue) 0.59%, var(--primary-green) 99.79%);
  --gradient03: linear-gradient(224.63deg, var(--primary-blue) 0%, var(--primary-green) 100%);
  --gradient04: linear-gradient(45deg, var(--primary-blue) 0%, var(--primary-green) 100%);
}

html,
body {
  padding: 0;
  margin: 0;
}

th, td {
  border-bottom: 1px solid #ddd;
}

a {
  cursor: pointer;
  text-decoration: none;
}

b {
  font-weight: 700;
}

*, .button, .notification, :after, :before, input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #D8D8D8;

}

::-webkit-scrollbar-thumb:window-inactive {
	background: #D8D8D8;
}

.bold {
  font-weight: 700 !important;
}

.white {
  color: var(--default-white) !important;
}

.blue {
  color: var(--primary-blue) !important;
}

.green {
  color: var(--primary-green) !important;
}

.barlow--size48 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 48px;
  line-height: 120%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size40 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 40px;
  line-height: 120%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size32 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 120%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size24 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size20 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size18 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size16 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size14 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.barlow--size12 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.links--barlow--size20 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 80%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 400;
}

.links--barlow--size18 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 80%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 400;
}

.links--barlow--size16 {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 80%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 400;
}

.open-sans--size40 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 40px;
  line-height: 120%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.open-sans--size32 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 120%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.open-sans--size24 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.open-sans--size20 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.open-sans--size18 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.open-sans--size16 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.open-sans--size14 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.open-sans--size12 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--primary-gray-dark);
  font-weight: 400;
}

.links--open-sans--size20 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 80%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 400;
}

.links--open-sans--size18 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 80%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 400;
}

.links--open-sans--size16 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 80%;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 400;
}

.PageDefault_container__omjwn {
  padding: 0px;
  overflow: hidden;
}

.PageDefault_main__aX8mX {

  padding: 0px 120px;
}

@media only screen and (min-width: 1440px) {
  .PageDefault_container__omjwn {
    margin: 0px calc((100vw - 1440px)/2);
  }
}

@media only screen and (max-width: 1024px) {
  .PageDefault_main__aX8mX {
    margin-top: 0px;
    padding: 0px 24px;
  }
}

@media only screen and (max-width: 425px) {
  .PageDefault_main__aX8mX {
    margin-top: 0px;
    padding: 0px 8px;
  }
}

